import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { useState } from "react";

export default function Formulaire(props) {
    const [nationality, setNatinality] = useState('')
    const [validesPID, setPIDValidity] = useState('')
    const [validesTdS, setTdSValidity] = useState('')

    function valide(){
        props.setValide(validesPID==='oui'&&(nationality==='sl'?validesTdS==='oui':true))
    }

    return(
    <Box sx={{borderRadius:2, overflow:'hidden', minWidth:'400px', width:'42%', backgroundColor:"white"}}>
        <Typography variant="h3" sx={{backgroundColor:'primary.main'}} color="white" style={{textAlign:'center', paddingBlock:'15px' }}>Formulaire de vérification</Typography>
        {(validesPID==='non'||(nationality==='sl'&&validesTdS==='non'))&&<Typography variant="subtitle1" color='red.main' align="center" marginBlock={2}>Il vous faut impérativement {nationality==='sl'?'un passeport et un Titre de séjour valides':'un passeport ou une CNI valide'} pour voyager.<br/>
        Veuillez vous rapprocher de l'ambassade Française à Castries ou de la préfecture de Fort-de-France.</Typography>}
        <Box display='flex' flexDirection='column' sx={{margin:3}}>
        <Typography variant="subtitle1">Vous êtes:</Typography>
        <RadioGroup value={nationality} row sx={{marginX:'20px', marginY:'10px'}} onChange={(e)=>setNatinality(e.target.value)}>
            <FormControlLabel value={"fr/eu"} control={<Radio />} label="Français(e)/Européen(ne)" />
            <FormControlLabel value={"sl"} control={<Radio />} label="Sainte-Lucien(ne)" />
        </RadioGroup>
        {nationality!==''&&<><Typography variant="subtitle1">Votre pièce d'identité(passeport ou CNI) est-elle en cours de validité ?</Typography>
        <RadioGroup value={validesPID} row sx={{marginX:'20px', marginY:'10px'}} onChange={(e)=>setPIDValidity(e.target.value)}>
            <FormControlLabel value={"oui"} control={<Radio />} label="Oui" />
            <FormControlLabel value={"non"} control={<Radio />} label="Non" />
        </RadioGroup></>}
        {nationality==='sl'&&<><Typography variant="subtitle1" marginTop={2}>Votre Titre de séjour est-il en cours de validité ?</Typography>
        <RadioGroup row value={validesTdS} sx={{marginX:'20px', marginY:'10px'}} onChange={(e)=>setTdSValidity(e.target.value)}>
            <FormControlLabel value={"oui"} control={<Radio />} label="Oui" />
            <FormControlLabel value={"non"} control={<Radio />} label="Non" />
        </RadioGroup></>}
        <Button disabled={validesPID!=='oui'||(nationality==='sl'&&validesTdS!=='oui')} variant="contained" sx={{alignSelf:'center',marginTop:3}} onClick={valide}>valider</Button>
        </Box>
    </Box>)
}