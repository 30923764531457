import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { Backdrop, Box, Button, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { API } from "../asset/conf";

export default function Confirmation() {
    const {reservation, passagers} = useContext(Context)
    const navigate = useNavigate()
    const [billets, setBillets] = useState([])
    const [load, setLoad] = useState(true)

    function downloadBillet(psgId) {
        setLoad(true);
        fetch(API.concat(`/billets/${JSON.stringify({_id:{$in: billets.filter(bl=>bl.passager===psgId).map(bl=>bl._id)}})}/download`), {method:'GET'})
        .then(async(response)=>{
            if (response.status === 200) {
                const arrayBuffer = await response.arrayBuffer();
                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                const downloadUrl = window.URL.createObjectURL(blob)
                // Créer un lien temporaire et cliquer dessus pour déclencher le téléchargement
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'billet.pdf'; 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // Libérer l'URL de l'objet
                window.URL.revokeObjectURL(downloadUrl);
            }setLoad(false)
        })
    }

    useEffect(()=>{ 
        if (!reservation) {
            navigate('/')
        } else {
            fetch(API.concat(`/billets/${JSON.stringify({_id:{$in: reservation.billets}})}`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }}).then(async response=>{
                if (response.status === 200) {
                    const json = await response.json()
                    setBillets(json)
                } setLoad(false)
            })
        }
    }, [reservation])

    return(
        <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
            <Paper elevation={5} sx={{padding:'20px', display:'flex', flexDirection:'column'}}>
                <Typography variant="h3" align="center" marginBottom={5}>Félicitation!<br/>Votre réservation a bien été enregistrée sous la référence {reservation?.num}</Typography>
                <Typography variant="subtitle1" marginBottom={1}>Télécharger mes billets:</Typography>
                {reservation?.passagers?.map(psgId=>{
                    const psg = passagers.find(ps=>ps._id===psgId)
                return <Stack key={psgId} direction='row' alignItems='center' spacing={1}>
                    {psg&&<Typography sx={{textTransform:'capitalize'}}>- {psg.nom.toUpperCase()} {psg.prenom}</Typography>}
                    <Button onClick={()=>downloadBillet(psgId)} sx={{textTransform:'none', fontWeight:'500'}}>télécharger le billet</Button>
                </Stack>})}
                <Button href="https://caporosso.fr">Accueil Transport Express Caraïbes</Button>
            </Paper>
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}