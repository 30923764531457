import React, { useContext, useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, FormControlLabel, IconButton, Menu, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import background from '../asset/reduit_beach2.jpg';
import {API} from '../asset/conf';
import { Context } from "../App";
// import RotatDays from "./rotat-days";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/fr';
import Formulaire from "./formulaire";

moment.locale('fr');

export default function Home() {
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [isValide, setValide] = useState(false)
    
    const [ports, setports] = useState([])
    // const [tarifs, setTarifs] = useState([])
    const {isShopping,setShopping, setRotations, setPassagers} = useContext(Context)
    const [day, setDay] = useState('')
    const [date, setDate] = useState('')
    const [dispo_days, setDispoDays] = useState([])
    const [cat_passengers, setpassengers] = useState([{label:'Adulte (+12 ans)', count:1}, {label:'Enfant B (5-12 ans)', count:0}, {label:'Enfant A (1-4 ans)', count:0}, {label:'Nourrisson (-1 ans)', count:0}])

    const [dispo_rotations, setRtns] = useState([])

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null)
    };

    function getDispoDays(day){
        setLoad(true)
        fetch(API.concat(day==='shopping'?`/shopping`:`/reservation/rotations/${day}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }}).then(async response=>{
            if (response.status===200) {
                const json = await response.json(); 
                setRtns(json)
                const dates = [...new Set(json.filter(rtn=>moment.utc(rtn.date_depart).isAfter(moment.utc(moment().format('yyyy-MM-DD')),'d')).map(rtn=> moment.utc(rtn.date_depart).format('yyyy-MM-DD')))]
                setDispoDays(dates)
                setLoad(false)
            }else setLoad(true)
        })
    }

    function handleSave() {
        setLoad(true)
        const rtns = dispo_rotations.filter(rtn=> moment.utc(rtn.date_depart).format('yyyy-MM-DD')===date)
        Promise.all(rtns.map(rtn=>{return new Promise((resolve, reject) => {
            rtn.from = ports.find(port=> port._id===rtn.from)
            rtn.to = ports.find(port=> port._id===rtn.to)
            resolve(rtn)
        })})).then(rtns=>{
            setRotations(rtns)
            setPassagers(new Array(cat_passengers.reduce((count, current)=>count+current.count,0)).fill(''))
            navigate('/passagers')
        })
    }

    useEffect(()=>{
        function getPorts() {
            fetch(API.concat(`/reservation/ports`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                }
            }).then(async response=>{
                if (response.status === 200) {
                    const json = await response.json()
                    setports(json)
                }
                setLoad(false)
            })
        }

        // function getTarifs() {
        //     fetch(API.concat(`/reservation/tarifs`), {method:'GET', headers:{
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json'
        //         }
        //     }).then(async response=>{
        //         if (response.status === 200) {
        //             const json = await response.json()
        //             setTarifs(json)
        //         }
        //     })
        // }

        getPorts(); //getTarifs()
    }, [])

    return(
        <Box display='flex' flex={1} flexDirection='column'>
            <Box display='flex' alignItems='center' justifyContent='center' sx={{backgroundImage: `url(${background})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', height:'100%', minHeight:'500px', paddingInline:'30px', paddingBlock:'40px'}}>
                {isValide?<Box sx={{borderRadius:2, overflow:'hidden', minWidth:'400px', width:'42%', backgroundColor:"white"}}>
                    <Typography variant="h3" sx={{backgroundColor:'rose.main'}} color="white" style={{textAlign:'center', paddingBlock:'15px' }}>Réservez votre billet</Typography>
                    <RadioGroup value={isShopping? "shopping" : day} row sx={{marginX:'20px', marginY:'10px'}}
                    onChange={(e)=> {setDay(e.target.value); setShopping(e.target.value==='shopping'); setRotations([]); getDispoDays(e.target.value)}}>
                        <Box>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Typography variant="subtitle1">Journées découverte:</Typography>
                                <FormControlLabel value={"lundi"} control={<Radio />} label="Lundi" />
                                <FormControlLabel value={"mardi"} control={<Radio />} label="Mardi" />
                            </Stack>
                                <FormControlLabel value={"jeudi"} control={<Radio />} label="Jeudi - voyage avec tes colis" />
                                <FormControlLabel value={"vendredi"} control={<Radio />} label="Vendredi - Friday night" />
                                <FormControlLabel value={"shopping"} control={<Radio />} label="Journées shopping" />
                        </Box>
                    </RadioGroup>
                    <Stack component='form' onSubmit={handleSave} direction='column' alignItems='center' spacing={2} marginX='20px' marginBottom='20px'>
                        <TextField required select fullWidth label='Date' value={date} onChange={e=> setDate(e.target.value)}>
                            {dispo_days.map((day, index)=>
                            <MenuItem key={index} value={day}>{moment.utc(day).format('dddd D MMMM YYYY')}</MenuItem>)}
                            {dispo_days.length<1&&<Typography align="center">Aucune {isShopping?'journée shopping':'traversée'} programmée à ce jour.<br/>Veuillez ré-essayer plus tard</Typography>}
                        </TextField>
                        <Button fullWidth variant="outlined"
                        style={{color:'black', textTransform:'none', whiteSpace:'pre-line'}}
                        aria-controls={open ? 'Passagers' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        >
                            <Typography><span style={{fontWeight:'500'}}>Passagers</span><br/>
                            {cat_passengers.reduce((all, current)=>all+current.count,0)} passager(s)
                            </Typography>
                        </Button>
                        <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'Passagers',
                        }}
                        >
                            {cat_passengers.map(cat_passenger=>
                            <MenuItem key={cat_passenger.label}>
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label){
                                    p.count+=1
                                }return p }))}><Add/></IconButton>
                                {cat_passenger.count}
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label&&p.count>0){
                                    p.count-=1
                                }return p }))}><Remove/></IconButton>
                                <span style={{textTransform:'capitalize'}}>{cat_passenger.label}</span>
                            </MenuItem>)}
                        </Menu>
                        <Button variant="contained"
                        type='submit'
                        disabled={date===''||cat_passengers[0].count<=0}
                        sx={{ width:'90%', borderRadius:1}}>
                            réserver
                        </Button>
                    </Stack>
                </Box>:<Formulaire {...{setValide}}/>}
            </Box>
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}