export const API = 'https://api.tecresa.fr'
export const nationalities = [
    {label: "Français", pieces: ['passeport', 'cni']},
    {label: "Européens", pieces: ['passeport']},
    // {label: "USA", pieces: ['passeport']},
    // {label: "Canada", pieces: ['passeport']},
    // {label: "Angleterre", pieces: ['passeport']},
    {label: "Sainte Lucie - avec Titre de séjour", pieces: ['passeport', 'Titre de séjour']},
    // {label: "Sainte Lucie - CARICOM (Visiteur avec VISA)", pieces: ['passeport', 'VISA']},
    // {label: "Sainte Lucie - CARICOM (Visiteur sans VISA)", pieces: ['passeport', 'Assurance médical', "Certificat d'hébergement/Réservation hôtel"]},
]