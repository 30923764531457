import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { API } from "../asset/conf";
import { useNavigate } from "react-router-dom";
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Context } from "../App";

export default function PayElement(props) {
    const navigate = useNavigate()
    const stripe = useStripe()
    const elements = useElements()
    const {isAR, rotations, passagers, mail, tel, contact, address1, address2, zipcode, city, country, setReservation} = useContext(Context)
    const [load, setLoad] = useState(false)
    const [msg, setMsg] = useState('')

    async function sendRsv(pay_ref) {
        setLoad(true);
        
        const rsv = {
            isA_R: isAR,
            date_depart: new Date(rotations[0].date_depart),
            rotations: rotations.map(rtn=> rtn._id),
            passagers: passagers.map(psg=>psg._id),
            total: props.price,
            rest_to_pay: 0,
            mail:mail,
            tel:tel,
            contact:contact,
            address1:address1,
            address2:address2,
            zipcode:zipcode,
            city:city,
            country:country,
            pay_status: 'payé',
            where: 'Web',
            is_journee: !rotations.some(rt=>!rt.is_journee),
            payments: [{
                date: new Date(),
                montant: props.price,
                ref:pay_ref,
                mode: 'web',
            }],
        }

        if(isAR) rsv.date_retour = new Date(rotations[1].date_depart)
        if(props.promo!=='') rsv.promo_code = props.promo
        fetch(API.concat('/reservation/payed/register'), {method:'POST', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify(rsv)
        }).then(async response=>{
            const reservation = response.status===200? await response.json() : rsv
            setReservation(reservation)
            setLoad(false)
            navigate('/confirmation')
        })
    }

    async function handlePaye(event) {
        event.preventDefault()
        setMsg('')
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setLoad(true)
        
        try {
            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                  return_url: `${window.location.origin}/confirmation`,
                },
                redirect:"if_required"
            });
          
              if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                setMsg(result.error.message);
              } else if(result.paymentIntent&&result.paymentIntent.status==='succeeded') {
                sendRsv(result.paymentIntent.id)
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
              }
            setLoad(false)
        } catch (error) {
            setMsg('Erreur lors de la confirmation du paiement')
            setLoad(false)
            console.error('Erreur lors de la confirmation du paiement :', error);
        }
    }
    
    return(
        <Box component='form' onSubmit={handlePaye} display='flex' flexDirection='column'>
            <Box alignSelf='center' width='40%' minWidth='300px'>
                <PaymentElement />
                <Typography variant="subtitle1" color='red.main' marginTop={1}>{msg}</Typography>
            </Box>
            <Button variant="contained" type="submit" disabled={!stripe||load} sx={{marginTop:2}} style={{alignSelf:'center'}}>payer {props.price.toFixed(2)}€</Button>
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}